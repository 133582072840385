import { t, Trans } from '@lingui/macro';
import { PURCHASE_OFFER_DISPLAY_QUERY_KEY } from '@milo/constants';
import {
  BulletPoints,
  Button,
  Button2,
  formatCurrency,
  getProductIntervalString,
  Link2,
  MiloPass,
  objectToQueryString,
  ReactFC,
  SideScroller,
  Skeleton,
  trackEvent,
  useAuthentication,
  useProducts,
  useSearchQuery,
} from '@milo/react';
import { ProductInterval, PurchaseMode } from '@milo/types';
import cn from 'classnames';
import qs from 'query-string';
import { useState } from 'react';
import config from '../../config';
import { ProfitableWordRotation } from '../Home';

type PurchaseWidgetProps = {
  onChange?: Function;
  showOffer?: boolean;
  purchaseText?: string;
};

export const PurchaseWidget: ReactFC<PurchaseWidgetProps> = ({
  onChange = () => {},
  showOffer = true,
  purchaseText = t`Acheter`,
}) => {
  const { user } = useAuthentication();
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const { data: products = [], isLoading: productsLoading } = useProducts();

  const loading = productsLoading;

  const displayedProducts = products.filter((p) => p.displayed);

  const purchaseItems = displayedProducts.map((p) => {
    return {
      ...p,
      discountedPrice: p.price_cents_cad,
      redText: p.special_price_text,
    };
  });

  const _showOffer = showOffer && !purchaseItems[selectedItemIndex]?.is_recurring;

  if (loading) {
    return (
      <div className="animate-fade-in">
        <Skeleton className="mx-auto mb-14 h-8 w-3/4 rounded-lg" />
        <div className="mb-20 flex justify-center gap-3">
          {[1, 2].map((x) => (
            <div
              key={`skeleton-${x}`}
              className="flex h-96 w-64 flex-col items-center gap-5 rounded-lg border-4 p-10 shadow-lg"
            >
              <Skeleton className="h-20 w-20 rounded-lg" />
              <Skeleton className="h-4 w-40 rounded-lg" />
              <Skeleton className="h-4 w-40 rounded-lg" />
              <Skeleton className="h-4 w-40 rounded-lg" />
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center gap-4">
          <Skeleton className="h-14 w-48 rounded-full" />
          <Skeleton className="h-14 w-48 rounded-full" />
        </div>
      </div>
    );
  }

  const selectedProduct = purchaseItems[selectedItemIndex];
  const selectedProductId = selectedProduct?.id;

  return (
    <div className="animate-fade-in-top text-center">
      <h2 className="mx-auto mb-8 max-w-2xl text-center text-4xl font-bold leading-10">
        Le <MiloPass className="inline h-8" />
      </h2>
      <p className="mb-6 text-2xl max-w-4xl mx-auto text-center">
        Permet d'obtenir <span className="text-red">20%</span> de réduction dans près de{' '}
        <span className="text-red">400 adresses</span> à travers tout le Québec et d'économiser en moyenne <span className="text-red">800$</span> par année !
      </p>
      <div className="mb-10">
        <SideScroller itemsGap={1}>
          {purchaseItems.map((item, index) => (
            <PurchaseItem
              intervalType={item?.interval_type}
              intervalCount={item?.interval_count}
              isRecurring={item?.is_recurring}
              key={`purchase-item-${index}`}
              imageUrl={item?.image_url}
              price={item?.base_price}
              discountedPrice={item?.price_cents_cad}
              redText={item.redText}
              selected={selectedItemIndex === index}
              onClick={() => {
                onChange(purchaseItems[index]?.id);
                setSelectedItemIndex(index);
              }}
            />
          ))}
        </SideScroller>
      </div>

      <div className="mb-4 flex flex-wrap items-center justify-center gap-2">
        <Link2
          className="animate-fade-in-top"
          onClickCapture={() =>
            trackEvent('cta_purchase_click', {
              user_id: user?.id,
              path: location.pathname,
              purchase_mode: PurchaseMode['Self'],
            })
          }
          href={`/purchase/checkout?${objectToQueryString({
            ...qs.parse(location.search),
            productId: selectedProductId,
          })}`}
        >
          <Button2 className="min-w-[200px]">{purchaseText}</Button2>
        </Link2>
        {_showOffer && (
          <Link2
            className="animate-fade-in-top"
            href={`/purchase/checkout?${objectToQueryString({
              ...qs.parse(location.search),
              purchaseMode: 'offer',
              productId: config.STRIPE_12_MONTH_GIFT_ID,
            })}`}
          >
            <Button2 className="min-w-[200px]" variant="teal">
              <Trans>Offrir</Trans>
            </Button2>
          </Link2>
        )}
      </div>
      <p className="text-gray-500">
        * <Trans>Vous pouvez acheter un pass M ta région maintenant et l'activer plus tard</Trans>
      </p>
    </div>
  );
};

const PurchaseItem: ReactFC<{
  discountedPrice?: number | null;
  redText?: string | null;
  imageUrl: string;
  intervalCount: number;
  intervalType: ProductInterval;
  isRecurring: boolean;
  onClick: () => void;
  price?: number | null;
  promoCode?: string | null;
  selected: boolean;
}> = ({
  intervalCount,
  intervalType,
  isRecurring,
  imageUrl,
  price,
  discountedPrice = null,
  redText = null,
  selected = false,
  ...props
}) => {
  const discountActive = typeof discountedPrice === 'number';

  return (
    <button
      type="button"
      className={`relative mb-2 w-[75vw] max-w-[300px] flex-1 cursor-pointer overflow-hidden rounded-2xl border-[4px] ${
        selected ? 'border-red' : 'border-gray-200'
      } bg-white py-10 shadow-md transition-all duration-300`}
      {...props}
    >
      <p
        className={cn('-mt-10 mb-5 flex h-10 items-center justify-center px-2 py-1 font-semibold text-white',
          isRecurring ? 'bg-green' : 'bg-red',
          {
          invisible: !redText,
        })}
      >
        {redText}
      </p>
      <span className={`transition-all duration-300 ${selected ? '' : 'opacity-75 grayscale'}`}>
        <div className="mb-10">
          <img src={imageUrl} className="mx-auto w-24" />
        </div>

        {
          <div className="relative">
            <h3
              className={`text-4xl font-bold ${
                discountActive &&
                'absolute -top-4 left-1/2 mr-6 inline-block -translate-x-1/2 text-base text-medium-gray line-through'
              }`}
            >
              {price ? formatCurrency(price, { decimals: (price / 100) % 1 === 0 ? 0 : 2 }) : ''}
            </h3>

            {discountActive && (
              <h3 className="inline-block text-4xl font-bold text-red">
                {formatCurrency(discountedPrice, { decimals: (discountedPrice / 100) % 1 === 0 ? 0 : 2 })}
              </h3>
            )}
          </div>
        }

        <div className="">
          <span className="text-xl font-bold">{getProductIntervalString(intervalType, intervalCount)}</span>
        </div>

        <div className="text-gray-400">
          {(isRecurring && <Trans>renouvellement automatique</Trans>) || <Trans>paiement non récurrent</Trans>}
        </div>
      </span>
    </button>
  );
};

const BulkPurchase = () => {
  return (
    <div className="flex flex-wrap items-center justify-center gap-4 rounded-2xl bg-[#B50F35] px-6 py-6 text-center sm:px-0">
      <img src="/images/purchase/gift.png" alt={t`pass M ta région en cadeau`} />
      <h3 className="text-2xl font-bold text-white">
        <Trans>Vous voulez offrir plusieurs pass M ta région?</Trans>
      </h3>
      <Link2
        href={`
            mailto:membre@miloguide.com?subject=${encodeURIComponent(t`Acheter plusieurs pass M ta région`)}
        `}
      >
        <Button className="!border-red text-red" variant="secondary">
          <Trans>Contactez nous directement</Trans>
        </Button>
      </Link2>
    </div>
  );
};

const MiloPassBullets = () => {
  return (
    <BulletPoints
      items={[
        t`10$ à 250$ de réduction à chaque fois que vous visitez une expérience M ta région`,
        t`Créez vos itinéraires découvertes à l’aide de notre carte interactive géolocalisée`,
        t`Laissez-vous inspirer par l’un de nos 150 guides découvertes`,
      ]}
    />
  );
};

export const PurchaseDetails = () => {
  const q = useSearchQuery();
  const hideOffer = q.query?.[PURCHASE_OFFER_DISPLAY_QUERY_KEY] === 'false';

  return (
    <div>
      <div className="relative mb-36 bg-gray-lighter">
        <div
          // This is the bottom rounded background
          className="absolute left-0 right-0 top-full h-20 bg-gray-lighter"
          style={{
            borderBottomLeftRadius: '50% 100%',
            borderBottomRightRadius: '50% 100%',
          }}
        />
        <div className="web-containe flex flex-col items-center">
          {/* <MiloPass className="mb-10 mt-10 h-12" /> */}

          <div className="mx-auto mb-12 mt-10 w-[95vw] max-w-6xl">
            <PurchaseWidget
              purchaseText={hideOffer ? t`J'achète un pass M ta région!` : t`Acheter`}
              showOffer={!hideOffer}
            />
          </div>

          <div className="mb-20 text-center">
            <ProfitableWordRotation />
          </div>

          <div className="mb-20">
            <h2 className="mb-8 text-center text-4xl font-bold">
              <Trans>Inclus avec chaque pass M ta région</Trans>
            </h2>
            <MiloPassBullets />
          </div>

          {/* This goes here instead of on top */}
          <div className="mb-10 w-full max-w-5xl">
            <BulkPurchase />
          </div>
        </div>
      </div>
      <div className="web-container text-center">
        <HowItWorks />
      </div>
    </div>
  );
};

const HowItWorksItem: ReactFC<{
  stepNumber: number;
  title: string;
  text: string;
  image: string;
  imageAlt: string;
  isReverse?: boolean;
}> = ({ stepNumber, title, text, image, imageAlt, isReverse = false }) => {
  return (
    <div
      className={`
      mx-auto mb-20 flex max-w-5xl flex-wrap items-center justify-around gap-6 md:mb-10
      ${isReverse ? 'flex-row-reverse' : ''}
    `}
    >
      <div className="min-w-[300px] basis-1/3">
        <p className="font-outline mb-4 text-7xl font-bold">{stepNumber}</p>
        <h2 className="mb-4 text-2xl font-bold">{title}</h2>
        <p>{text}</p>
      </div>

      <div className="min-w-[300px] basis-1/3 overflow-hidden rounded-3xl shadow-lg">
        <img src={image} alt={imageAlt} className="w-full" />
      </div>
    </div>
  );
};

const HowItWorks = () => {
  return (
    <div>
      <h2 className="mb-8 text-4xl font-bold">
        <Trans>Comment ça marche?</Trans>
      </h2>
      <p className="mb-20 text-2xl">
        <Trans>Économisez en faisant de nouvelles découvertes.</Trans>
      </p>

      <div>
        <HowItWorksItem
          image={'/images/how-it-works/1.png'}
          imageAlt={t``}
          stepNumber={1}
          title={t`Choisissez votre pass M ta région`}
          text={t`Choisissez parmi une de nos options de pass M ta région selon celle qui répond le plus à vos besoins.`}
        />
        <HowItWorksItem
          isReverse
          image={'/images/how-it-works/2.png'}
          imageAlt={t``}
          stepNumber={2}
          title={t`Planifiez vos sorties avec l'application M ta région`}
          text={t`M ta région rassemble des centaines d'activités à découvrir au Québec. Plus besoin de chercher où manger, magasiner, relaxer, s'oxygéner ou se cultiver.`}
        />
        <HowItWorksItem
          image={'/images/how-it-works/3.png'}
          imageAlt={t``}
          stepNumber={3}
          title={t`Construisez vos parcours découvertes personnalisés`}
          text={t`Planifiez vos escapades et créez des itinéraires personnalisés en fonction de vos intérêts à l'aide de notre carte interactive géolocalisée.`}
        />
        <HowItWorksItem
          isReverse
          image={'/images/how-it-works/4.png'}
          imageAlt={t``}
          stepNumber={4}
          title={t`Vivez et savourez les expériences`}
          text={t`Partez visiter et apprécier les expériences que vous avez choisies, avec le confort de savoir que vous allez économiser sur votre facture totale.`}
        />
        <HowItWorksItem
          image={'/images/how-it-works/5.png'}
          imageAlt={t``}
          stepNumber={5}
          title={t`Obtenez votre réduction-découverte dans l'application M ta région`}
          text={t`Annoncez au commerçant que vous êtes détenteur d'un pass M ta région et découvrez combien vous allez économiser en utilisant l'application mobile M ta région.`}
        />
      </div>
    </div>
  );
};
