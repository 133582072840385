import { useState } from "react";
import { useAuthentication, useLocalStorage } from "@milo/react";
import { Modal, ModalBody } from "./Modal";
import { Trans } from '@lingui/macro';
import { Text } from "@milo/react";
import { Button2 } from "@milo/react";

export const ValentinsDayModal = ({ disabled = false }: { disabled?: boolean }) => {
  const { isLoading, isFetching } = useAuthentication();
  const [hardDismissedTimestamp, setHardDismissedTimestamp] = useLocalStorage('valentin-days-modal-hard-dismissed', null);
  const [open, setOpen] = useState(true);
  const timeThreshold = 24 * 60 * 60 * 1000 * 7;

  const hideModalFor24h =
    hardDismissedTimestamp != null
      ? new Date().getTime() - new Date(hardDismissedTimestamp).getTime() <= timeThreshold
      : false;
  if (isLoading || isFetching) {
    return null;
  }

  const freeVisitModalOpenStateIsTrue = open;
  const excludedFromFreeVisitModal = ['/login', '/redeem', '/purchase', '/free-visit'].some((path) =>
    window.location.pathname.startsWith(path)
  );

  const showModal =
    freeVisitModalOpenStateIsTrue &&
    !hideModalFor24h &&
    !excludedFromFreeVisitModal;

  const hardClose = () => {
    setHardDismissedTimestamp(new Date());
    setOpen(false);
  };

  if (disabled) return null;

  return (
    <Modal closeOnBackdropClick={false} showCloseButton={true} open={showModal} onRequestClose={hardClose}>
      <ModalBody className="flex flex-col space-y-8 justify-center items-center">
        <Text className="font-bold text-center" size="h1">
          <Trans>Jeu Concours Saint-Valentin</Trans>
        </Text>
        <Text className="!text-gray text-center" size="h4">
          <Trans>
            Tentez de remporter <b>1267$ de cadeaux</b> chez nos partenaires pour 2 !
          </Trans>
        </Text>
        <div className="flex flex-col space-y-4 items-center">
          <a href="https://us12.list-manage.com/survey?u=75239b5592c5883243fe92a9f&id=9909e45347&attribution=false" target="_blank">
            <Button2>
              <Trans>Je participe</Trans>
            </Button2>
          </a>
          <a href={'/contracts/rules-st-valentin-contest.pdf'} target="_blank">
            <Button2>
              <Trans>Règlement du jeu</Trans>
            </Button2>
          </a>
        </div>
        <button className="text-medium-gray" onClick={hardClose}>
          <Trans>Ne plus afficher</Trans>
        </button>
      </ModalBody>
    </Modal>
  );
};
