// Milo types
import { LocalizedObject, DistributorDiscountApplicableProductIds } from './index';

// SQL types
export type JSONPrimitive = string | number | boolean | null;
export type JSONValue = JSONPrimitive | JSONObject | JSONArray;
export type JSONObject = { [member: string]: JSONValue };
export interface JSONArray extends Array<JSONValue> {}

/*
* This file was generated by a tool.
* Rerun sql-ts to regenerate this file.
*/
export type ActivationCodesRow = {
  "id": number 
  "user_created": string | null 
  "date_created": any | null 
  "user_updated": string | null 
  "date_updated": any | null 
  "purchase_user_id": number | null 
  "purchase_user_email": string | null 
  "purchase_stripe_customer_id": string | null 
  "code": string | null 
  "recipient_user_email": string | null 
  "extra_days": number | null 
  "used_by_id": number | null 
  "used_at": Date | null 
  "valid_at": Date | null 
  "data": Object | null 
  "stripe_product_id": string | null 
  "purchase_user_first_name": string | null 
  "purchase_user_last_name": string | null 
  "recipient_user_first_name": string | null 
  "recipient_user_last_name": string | null 
  "message": string | null 
  "purchase_mode": string | null 
  "send_date": Date | null 
  "sent_at": Date | null 
  "stripe_payment_intent_id": string | null 
  "stripe_subscription_id": string | null 
  "stripe_charge_id": string | null 
  "offered_product": string | null 
}
export type CategoriesRow = {
  "id": number 
  "date_created": any | null 
  "date_updated": any | null 
}
export type CategoriesTranslationsRow = {
  "id": number 
  "categories_id": number | null 
  "languages_code": string | null 
  "name": string | null 
}
export type CitiesRow = {
  "id": number 
  "name": Object | null 
  "region_id": number | null 
  "lat": number | null 
  "lng": number | null 
  "created_at": any | null 
  "updated_at": any | null 
}
export type CmsSessionsRow = {
  "token": string 
  "user_id": string 
  "expires_at": any 
  "date_created": any | null 
  "date_updated": any | null 
}
export type CmsUsersRow = {
  "id": string 
  "first_name": string | null 
  "middle_name": string | null 
  "last_name": string | null 
  "email": string 
  "password": string 
  "image": string | null 
  "date_created": any 
  "date_updated": any 
}
export type ContactsRow = {
  "id": number 
  "first_name": string | null 
  "last_name": string | null 
  "email": string | null 
  "office_phone": string | null 
  "office_phone_extension": string | null 
  "cell_phone": string | null 
  "position": string | null 
  "permissions": string | null 
  "experiences": any | null 
  "data": Object | null 
  "notes": string | null 
  "last_login_at": any | null 
  "created_date": any | null 
  "updated_date": any | null 
}
export type DistributorsRow = {
  "id": string 
  "name": LocalizedObject | null 
  "description": LocalizedObject | null 
  "image_url": string | null 
  "logo_url": string | null 
  "website_url": string | null 
  "created_at": any 
  "updated_at": any 
  "header_text": any | null 
  "offer_description": any | null 
  "extra_description": any | null 
  "slug": string 
}
export type DistributorsDiscountsRow = {
  "id": number 
  "active": boolean 
  "distributor_id": string 
  "percent_off": number 
  "created_at": any 
  "updated_at": any 
  "applicable_product_id": string 
}
export type DistributorsTermsContractsRow = {
  "id": number 
  "active": boolean 
  "distributor_id": number 
  "signed_date": any | null 
  "signed_contact_id": number | null 
  "signed_pdf_url": string | null 
  "auto_renew": boolean 
  "cancel_date": any | null 
  "cancel_contact_id": number | null 
  "tc_version": number 
  "tc_referral_percentage": number | null 
  "tc_penalty": number 
  "tc_initial_months_duration": number 
  "tc_restrictions": Object | null 
  "created_at": any 
  "updated_at": any 
  "notes": string | null 
}
export type DistributorsTransactionsRow = {
  "id": number 
  "distributor_id": string 
  "milo_user_id": number 
  "stripe_transaction_id": string 
  "created_at": any 
  "updated_at": any 
}
export type DistributorsUsersRow = {
  "id": number 
  "first_name": string 
  "last_name": string 
  "email": string 
  "password": string 
  "office_phone": string | null 
  "office_phone_extension": string | null 
  "cell_phone": string | null 
  "position": string | null 
  "permissions": string | null 
  "distributors_ids": any | null 
  "notes": string | null 
  "created_at": any | null 
  "updated_at": any | null 
}
export type EtlStripeCustomersRow = {
  "id": number 
  "user_id": number | null 
  "user_email": string | null 
  "stripe_customer_id": string | null 
  "has_active_subscription": boolean | null 
  "active_sub_start_date": any | null 
  "active_sub_cancel_date": any | null 
  "auto_renew": boolean | null 
  "cents_balance": number | null 
  "credit_card_status": card_status | null 
  "default_credit_card_last_4": number | null 
  "created_at": any | null 
}
export type ExperiencesRow = {
  "id": number 
  "main_image": string | null 
  "group_id": number | null 
  "street_address": string | null 
  "city": string | null 
  "postal_code": string | null 
  "province": string | null 
  "country": string | null 
  "lat": number | null 
  "lng": number | null 
  "region_id": number | null 
  "status": string | null 
  "savings": string | null 
  "website": string | null 
  "facebook": string | null 
  "instagram": string | null 
  "date_created": any | null 
  "date_updated": any | null 
  "user_created": string | null 
  "user_updated": string | null 
  "seo_image": string | null 
  "google_maps_url": string | null 
  "data": any | null 
  "business_contacts": any | null 
  "milo_contacts": any | null 
  "company_phone": string | null 
  "company_email": string | null 
  "date_published": Date | null 
  "is_legacy": boolean | null 
  "savings_percentage": number | null 
  "savings_max": number | null 
  "active": boolean | null 
  "thumbnail_image": string | null 
  "tags_ids": any | null 
  "vertical_id": number | null 
  "city_id": number | null 
  "name": LocalizedObject | null 
  "description": LocalizedObject | null 
  "seo_title": any | null 
  "reservation_conditions": LocalizedObject | null 
  "additional_images": any | null 
  "main_image_new": string | null 
  "verticals_ephemeral_ids": number[] | null 
  "opening_hours": any 
}
export type ExperiencesAnswersRow = {
  "id": number 
  "experience_id": number 
  "question_id": number 
  "answer": any | null 
  "created_at": any 
  "updated_at": any 
}
export type ExperiencesCategoriesRow = {
  "id": number 
  "user_created": string | null 
  "date_created": any | null 
  "user_updated": string | null 
  "date_updated": any | null 
  "experience_id": number | null 
  "category_id": number | null 
}
export type ExperiencesDirectusFilesRow = {
  "id": number 
  "experiences_id": number | null 
  "directus_files_id": string | null 
}
export type ExperiencesOwnersRow = {
  "id": number 
  "experience_id": number 
  "first_name": string 
  "last_name": string 
  "image_url": string | null 
  "created_at": any 
  "updated_at": any 
}
export type ExperiencesQuestionsRow = {
  "id": number 
  "question": any | null 
  "created_at": any 
  "updated_at": any 
}
export type ExperiencesTranslationsRow = {
  "id": number 
  "languages_id": string | null 
  "experiences_id": number | null 
  "name": string | null 
  "description": string | null 
  "seo_title": string | null 
  "reservation_conditions": string | null 
  "restrictions": string | null 
}
export type FeatureFlagsRow = {
  "id": string 
  "description": string 
  "conditions": any | null 
  "starts_at": any | null 
  "ends_at": any | null 
}
export type FreeVisitsRow = {
  "id": number 
  "user_id": number 
  "visit_id": number | null 
  "valid_until_date": any 
  "created_date": any 
  "updated_date": any 
}
export type GroupsRow = {
  "id": number 
  "user_created": string | null 
  "date_created": any | null 
  "user_updated": string | null 
  "date_updated": any | null 
  "name": string | null 
  "website": string | null 
  "data": Object | null 
  "admin_contacts": Object | null 
}
export type GuidesRow = {
  "id": number 
  "title": Object | null 
  "meta_description": Object | null 
  "long_description": Object | null 
  "region_id": number | null 
  "city_id": number | null 
  "vertical_id": number | null 
  "tags_ids": any | null 
  "main_image_url": string | null 
  "popular": boolean | null 
}
export type KnexMigrationsRow = {
  "id": number 
  "name": string | null 
  "batch": number | null 
  "migration_time": any | null 
}
export type KnexMigrationsLockRow = {
  "index": number 
  "is_locked": number | null 
}
export type LandingContestRow = {
  "id": number 
  "first_name": string | null 
  "last_name": string | null 
  "email": string | null 
  "contest_name": string | null 
  "created_at": any | null 
  "updated_at": any | null 
}
export type LanguagesRow = {
  "code": string 
  "name": string | null 
}
export type MailingListsRow = {
  "id": string 
  "name": LocalizedObject 
  "description": LocalizedObject 
  "created_date": any 
  "updated_date": any 
  "data": Object | null 
  "notes": string | null 
}
export type PartnerPromoCodesRow = {
  "id": number 
  "code": string 
  "experience_id": number 
  "visit_id": number | null 
  "date_created": any | null 
  "date_updated": any | null 
}
export type PartnerSessionsRow = {
  "token": string 
  "user_created": string | null 
  "date_created": any | null 
  "user_updated": string | null 
  "date_updated": any | null 
  "partner_id": number | null 
  "ip": string | null 
  "data": Object | null 
}
export type PartnerTermsContractsRow = {
  "id": number 
  "experience_id": number 
  "active": boolean | null 
  "signed_date": any | null 
  "signed_contact_id": number | null 
  "signed_pdf_url": string | null 
  "auto_renew": boolean 
  "cancel_date": any | null 
  "cancel_contact_id": number | null 
  "tc_version": number 
  "tc_type": string 
  "tc_percentage": number | null 
  "tc_percentage_max_invoice": number | null 
  "tc_savings_code": string | null 
  "tc_two_for_one_max_count": number | null 
  "tc_penalty": number 
  "tc_initial_months_duration": number 
  "tc_restrictions": Object | null 
  "created_date": any 
  "updated_date": any 
  "notes": string | null 
  "tc_two_for_one_display_amount": number | null 
  "has_promo_codes": boolean | null 
  "tc_promo_code_instructions": any 
}
export type PartnerUsersRow = {
  "id": number 
  "user_created": string | null 
  "date_created": any | null 
  "user_updated": string | null 
  "date_updated": any | null 
  "is_admin": boolean 
  "email": string 
  "cell_phone": string | null 
  "first_name": string | null 
  "last_name": string | null 
  "last_login_at": Date | null 
  "experience_id": number | null 
}
export type PartnerUsersSessionsRow = {
  "token": string 
  "user_created": string | null 
  "date_created": any | null 
  "user_updated": string | null 
  "date_updated": any | null 
  "user_id": number | null 
  "ip": string | null 
  "data": Object | null 
}
export type PartnersRow = {
  "id": number 
  "connection_identifier": string | null 
  "general_pass": string | null 
  "admin_pass": string | null 
  "exp_ids": any 
  "date_created": any | null 
  "date_updated": any | null 
  "name": string | null 
  "reset_password_token": string | null 
}
export type PartnershipContractsRow = {
  "id": number 
  "user_created": string | null 
  "date_created": any | null 
  "user_updated": string | null 
  "date_updated": any | null 
  "version": string | null 
  "link": string | null 
}
export type PartnershipsRow = {
  "id": number 
  "user_created": string | null 
  "date_created": any | null 
  "user_updated": string | null 
  "date_updated": any | null 
  "onboarding_code": string | null 
  "experience_id": number | null 
  "accept_terms_date": Date | null 
  "accept_terms_partner_user_id": number | null 
  "passed_onboarding_date": Date | null 
  "auto_renew": boolean 
  "contract_version": number | null 
}
export type PointsOfInterestRow = {
  "id": number 
  "name": LocalizedObject 
  "description": LocalizedObject 
  "type": string 
  "city": LocalizedObject 
  "lat": number 
  "lng": number 
  "link": string | null 
  "start_date": any | null 
  "end_date": any | null 
  "primary_tag_id": any 
  "created_at": any 
  "updated_at": any 
  "partner_id": number | null 
}
export type PointsOfInterestPartnersRow = {
  "id": number 
  "name": LocalizedObject 
  "description": LocalizedObject 
  "logo_url": string 
  "website_url": string | null 
  "created_at": any 
  "updated_at": any 
}
export type PointsOfInterestSecondaryTagsRow = {
  "id": number 
  "point_of_interest_id": number 
  "tag_id": number 
  "created_at": any 
  "updated_at": any 
}
export type ProductsRow = {
  "id": string 
  "name": any 
  "description": any 
  "price_cents_cad": number 
  "interval_type": string 
  "interval_count": number 
  "is_recurring": boolean 
  "created_at": any 
  "updated_at": any 
  "active": boolean 
  "svg_icon": string 
  "base_price": number | null 
}
export type PromoCodesRow = {
  "id": number 
  "date_created": any | null 
  "date_updated": any | null 
  "code": string | null 
  "amount_off": number | null 
  "percent_off": number | null 
  "usage_count": number | null 
  "max_usage_count": number | null 
  "data": Object | null 
  "valid_at": any | null 
  "expires_at": any | null 
  "extra_days": number | null 
  "applicable_product_id": string | null 
  "notes": string | null 
  "user_id": number | null 
  "source": string | null 
}
export type PromotionalPartnerUserRow = {
  "promotional_partner_id": string 
  "milo_user_id": number 
  "partner_user_id": string 
  "verified": boolean 
  "active": boolean 
  "created_at": any 
  "updated_at": any 
}
export type PromotionalPartnersRow = {
  "id": string 
  "active": boolean 
  "name": string 
  "description": string 
  "created_at": any 
  "updated_at": any 
}
export type PromotionsRow = {
  "id": number 
  "name": LocalizedObject 
  "promo_codes_ids": any 
  "starts_at": any 
  "ends_at": any 
  "created_at": any 
  "updated_at": any 
  "description": any | null 
}
export type RedeemCodesRow = {
  "id": number 
  "code": string 
  "partner": string 
  "days": number 
  "used_by": number | null 
  "used_at": any | null 
  "created_at": any 
  "updated_at": any 
  "valid_at": any | null 
  "expires_at": any | null 
  "transaction_id": string | null 
}
export type RedeemCodesTransactionsRow = {
  "id": string 
  "order_number": string 
  "purchase_quantity": number 
  "purchase_amount_cents_cad": number 
  "buyer_id": string 
  "buyer_first_name": string | null 
  "buyer_last_name": string | null 
  "buyer_email": string 
  "payment_status": string 
  "created_at": any 
  "updated_at": any 
}
export type RegionsRow = {
  "id": number 
  "date_created": Date | null 
  "date_updated": Date | null 
  "lat": number | null 
  "lng": number | null 
  "name": LocalizedObject | null 
  "description": LocalizedObject | null 
}
export type RegionsTranslationsRow = {
  "id": number 
  "regions_id": number | null 
  "languages_code": string | null 
  "name": string | null 
}
export type SavingsMatrixRow = {
  "code": string 
  "user_created": string | null 
  "date_created": any | null 
  "user_updated": string | null 
  "date_updated": any | null 
  "amount1": number | null 
  "amount2": number | null 
  "amount3": number | null 
  "savings1": number | null 
  "savings2": number | null 
  "savings3": number | null 
  "active": boolean 
}
export type SubscriptionsRow = {
  "id": number 
  "user_id": number | null 
  "stripe_sub_id": string | null 
  "created_date": any | null 
  "updated_date": any | null 
  "legacy_table_id": number | null 
  "activation_codes_table_id": number | null 
  "stripe_created_at": any | null 
  "cancel_at_period_end": boolean | null 
  "current_period_end": any | null 
  "current_period_start": any | null 
  "status": string | null 
  "product_id": string | null 
  "price_id": string | null 
  "price_unit_amount_cents": number | null 
  "billing_cycle_anchor": any | null 
  "cancel_at": any | null 
  "active_days": number | null 
  "canceled_at": any | null 
  "ended_at": any | null 
  "type": string | null 
}
export type SubscriptionsRecurringRow = {
  "id": string 
  "user_id": number 
  "product_id": string 
  "stripe_sub_id": string 
  "current_period_end": any 
  "promo_code": string | null 
  "payment_succeeded_at": any | null 
  "stripe_payment_id": string | null 
  "payment_failed_at": any | null 
  "cancelled_at": any | null 
  "cancel_at_period_end": boolean 
  "created_at": any 
  "updated_at": any 
}
export type TagsRow = {
  "id": number 
  "name": any | null 
  "description": any | null 
  "created_at": any 
  "updated_at": any 
}
export type TermsAndConditionsRow = {
  "version": number 
  "content": string 
  "language": string 
  "created_at": any 
  "updated_at": any 
}
export type TypesRow = {
  "id": number 
  "name": Object 
  "description": Object | null 
  "key": string 
  "table": string 
  "column": string 
}
export type UnsubscribesRow = {
  "user_id": number | null 
  "unsub_lists_ids": any | null 
  "created_date": any 
  "updated_date": any 
}
export type UsersRow = {
  "id": number 
  "user_created": string | null 
  "date_created": any | null 
  "user_updated": string | null 
  "date_updated": any | null 
  "member_code": string | null 
  "first_name": string | null 
  "middle_name": string | null 
  "last_name": string | null 
  "email": string | null 
  "phone": string | null 
  "stripe_customer_id": string | null 
  "stripe_subscription_id": string | null 
  "privilege_reset_day": string | null 
  "facebook_id": string | null 
  "google_id": string | null 
  "data": Object | null 
  "last_login_at": Date | null 
  "region_id": number | null 
  "gender": string | null 
  "has_passed_onboarding": boolean 
  "birth_date": Date | null 
  "mailchimp_hash": string | null 
  "is_legacy": boolean | null 
  "address": Object 
  "notes": string | null 
  "source": string | null 
}
export type UsersLegacyRow = {
  "id": number 
  "date_created": any | null 
  "date_updated": any | null 
  "email": string | null 
  "first_name": string | null 
  "last_name": string | null 
  "free_days": number | null 
  "reactivated_at": Date | null 
  "stripe_customer_id": string | null 
  "new_user_id": number | null 
  "cents_credits": number | null 
  "user_source": string | null 
  "user_type": string | null 
}
export type UsersOtpRow = {
  "id": number 
  "user_created": string | null 
  "date_created": any | null 
  "user_updated": string | null 
  "date_updated": any | null 
  "email": string | null 
  "secret": string | null 
  "code": string | null 
  "attempts": number | null 
  "used_at": Date | null 
  "expires_at": Date | null 
}
export type UsersSessionsRow = {
  "token": string 
  "user_created": string | null 
  "date_created": any | null 
  "user_updated": string | null 
  "date_updated": any | null 
  "user_id": number | null 
  "ip": string | null 
  "data": Object | null 
}
export type VerticalsRow = {
  "id": number 
  "name": Object | null 
}
export type VerticalsEphemeralRow = {
  "id": number 
  "name": LocalizedObject | null 
  "start_month_day": string | null 
  "end_month_day": string | null 
  "created_at": any 
  "updated_at": any 
  "hex_color": string | null 
  "svg": string | null 
  "active": boolean | null 
  "slug": string | null 
}
export type VisitsRow = {
  "id": number 
  "user_created": string | null 
  "date_created": any | null 
  "user_updated": string | null 
  "date_updated": any | null 
  "user_id": number | null 
  "savings": number | null 
  "rating": number | null 
  "comment": string | null 
  "experience_id": number | null 
  "reduced_invoice_amount": number | null 
  "discovered_with_milo": boolean | null 
  "invoice_total_before_tax": number | null 
  "validated_by": string | null 
  "two_for_one_purchased": number | null 
  "two_for_one_received": number | null 
  "platform": string | null 
}
export type WishlistsRow = {
  "id": number 
  "user_created": string | null 
  "date_created": any | null 
  "user_updated": string | null 
  "date_updated": any | null 
  "name": string | null 
  "user_id": number | null 
}
export type WishlistsItemsRow = {
  "id": number 
  "user_created": string | null 
  "date_created": any | null 
  "user_updated": string | null 
  "date_updated": any | null 
  "experience_id": number | null 
  "wishlist_id": number | null 
}
export enum card_status {
  active = "active",
  inactive = "inactive",
}
